@import url('variables.css') screen;

.ion-color-favorite {
  --ion-color-base: #69bb7b;
  --ion-color-base-rgb: 105, 187, 123;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #5ca56c;
  --ion-color-tint: #78c288;
}

.ion-color-twitter {
  --ion-color-base: #1da1f4;
  --ion-color-base-rgb: 29, 161, 244;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1a8ed7;
  --ion-color-tint: #34aaf5;
}

.ion-color-google {
  --ion-color-base: #dc4a38;
  --ion-color-base-rgb: 220, 74, 56;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #c24131;
  --ion-color-tint: #e05c4c;
}

.ion-color-vimeo {
  --ion-color-base: #23b6ea;
  --ion-color-base-rgb: 35, 182, 234;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #1fa0ce;
  --ion-color-tint: #39bdec;
}

.ion-color-facebook {
  --ion-color-base: #3b5998;
  --ion-color-base-rgb: 59, 89, 152;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: #344e86;
  --ion-color-tint: #4f6aa2;
}

.ion-color-primary-shade {
  --ion-color-base: var(--ion-color-primary-shade);
  --ion-color-base-rgb: 185, 3, 11;
  --ion-color-contrast: #ffffff;
  --ion-color-contrast-rgb: 255, 255, 255;
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-tint: var(--ion-color-black-tint);
}

.ion-color-gray,
.ion-color-grey {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-superlight {
  --ion-color-base: var(--ion-color-superlight);
  --ion-color-base-rgb: var(--ion-color-superlight-rgb);
  --ion-color-contrast: var(--ion-color-superlight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-superlight-contrast-rgb);
  --ion-color-shade: var(--ion-color-superlight-shade);
  --ion-color-tint: var(--ion-color-superlight-tint);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

/* ion reset */

.list-ios {
  margin-bottom: 0;
}

ion-modal ion-page,
ion-modal .ion-page {
  min-width: 100px;
}

/* end ion reset */

h1 {
  display: block;
  color: lightgray;
  position: relative;
  text-decoration: underline;
  text-decoration-color: red;
}

h1::after {
  display: block;
  content: "DON'T USE H1 TAG ! ! !";
  color: red;
}

/* ion-input, input {
	direction: ltr !important;
	unicode-bidi: bidi-override !important;
} */

ion-input,
ion-select,
ion-datetime,
.sc-ion-input-md-h,
.sc-ion-input-ios-h,
.datetime-text,
.masked-input {
  --color: var(--ion-color-dark);
  color: var(--ion-color-dark);
  --placeholder-color: var(--ion-color-gray);
  font-family: primary-regular;
}

ion-datetime.datetime-placeholder {
  opacity: 0.33;
}

.big-label {
  font-size: var(--okx-big-label-font-size);
  font-weight: bold;
  color: var(--okx-big-label-color);
  padding: var(--okx-big-label-padding);
  margin: var(--okx-big-label-margin);
}

* {
  box-sizing: border-box;
  font-family: primary-regular;
  font-weight: initial;
  line-height: 1.5;
}
br {
  font-size: unset;
}

.padded {
  padding: var(--ion-padding);
}

ion-grid.no-padding {
  --ion-grid-padding: 0;
}

ion-grid.no-padding ion-col {
  padding: 0;
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color-activated: #fff;
}

.box-holder {
  background-color: var(--ion-color-box-holder);
  border-radius: 5px;
  padding: 0px 15px;
  border: 1px solid var(--ion-color-light);
}

.box-holder.box-holder--feedback {
  border: 1px solid var(--ion-color-light-gray);
}

/* .box-holder.box-holder--register {
  padding-top: 10px;
  border: 1px solid rgba(var(--ion-color-primary-rgb), 1);
} */

.box-holder ion-item {
  --padding-start: 0px;
  --border-color: var(--ion-separator-color);
}

.box-holder.box-holder--feedback ion-item {
  --border-color: 1px solid rgba(var(--ion-color-gray-rgb), 0.7);
}

.single-item {
  margin-bottom: 10px;
}

ion-label h2 {
  color: var(--ion-color-form-label-dark);
  font-family: poppins-semibold;
}

ion-note {
  color: var(--ion-color-note);
  font-size: var(--okx-note-font-size);
}

ion-label:not(.contact-number-label).ion-text-wrap {
  line-height: 1 !important;
}

ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}

ion-item ion-button {
  margin-bottom: 0;
  height: auto;
}

ion-button {
  --background-activated: var(--ion-color-light);
}

ion-button.rounded {
  --border-radius: 60px;
}
ion-button[size='small'] {
  height: 20px;
}
.float-input-holder {
  --padding-start: 0px;
  --border-color: var(--ion-color-form-label);
}

.top-small {
  margin-top: var(--ion-top-small);
}

.top-medium {
  margin-top: var(--ion-top-medium);
}

.verified-content {
  width: 190px;
  margin: 0 auto;
  text-align: center;
}

ion-button {
  height: 44px;
  --border-radius: 2px;
  --box-shadow: none;
  font-size: var(--ion-font-size);
  margin: 5px 0 0;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 600;
}

ion-button.validation {
  --background: var(--ion-color-white);
  --color: var(--ion-color-primary);
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-secondary);
}

ion-button.ion-color-primary {
  --border-style: solid;
  --border-width: 2px;
  --border-color: var(--ion-color-primary);
}

ion-button.ion-color-secondary {
  --ion-color-contrast: #fff !important;
  --background-activated: var(--ion-color-light);
  text-transform: uppercase;
}

ion-button.ion-color-secondary.button-disabled {
  --ion-color-base: rgba(var(--ion-color-gray-rgb), 0.5) !important;
  --color: rgba(var(--ion-color-gray), 1);
}

ion-button.ion-color-white {
  --border-style: none;
  --border-width: 0;
  --border-color: transparent;
}

ion-button.cancel {
  --background: var(--okx-cancel-button-color) !important;
  --color: var(--ion-color-primary);
}

ion-button.cancel.button-fill {
  --background: rgba(var(--ion-color-gray-rgb), 0.2) !important;
}

ion-button.button-outline {
  --background-activated: var(--ion-color-light);
  font-family: var(--ion-default-font-bold);
  text-transform: uppercase;
}

ion-button.link {
  --background: var(--okx-cancel-button-color) !important;
  --color: inherit;
  font-weight: 500;
  text-transform: initial;
  cursor: pointer;
}
ion-button.link button{
  padding: 0;

}
ion-button.link.underlined.ion-color-secondary {
  font-family: var(--ion-font-family);
  font-size: 10px;
  font-weight: normal;
}

ion-button[type='submit'] {
  --background: var(--ion-color-secondary);
  text-transform: uppercase;
}

ion-header ion-title {
  text-transform: uppercase;
  font-weight: bold;
}

.mixed-right,
.mixed-right * {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  justify-self: flex-end;
}

.mixed-left,
.mixed-left * {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  justify-self: flex-start;
}

.mixed-left,
.mixed-left * .grow {
  flex-grow: 0.5;
}

.poster {
  padding-top: 65%;
  background-color: transparent;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.no-padding {
  padding: 0;
}

/* span.link {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
}

span.link.link-primary {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.paddLR {
  padding-right: 0px;
  padding-left: 0px;
} */

ion-button.link,
ion-buttons ion-button.link,
.sc-ion-buttons-md-h.ion-color.sc-ion-buttons-md-s .button.link,
.ion-color .sc-ion-buttons-md-h.sc-ion-buttons-md-s .button.link,
.sc-ion-buttons-ios-h.ion-color.sc-ion-buttons-ios-s .button.link,
.ion-color .sc-ion-buttons-ios-h.sc-ion-buttons-ios-s .button.link {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: transparent;
  --background-active: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-color: transparent;
  --border-radius: 0;
  --border-style: none;
  --border-width: 0;
  --box-shadow: none;
  --ripple-color: transparent;
  --color: var(--ion-color-primary);
  --color-hover: var(--ion-color-primary-shade);
  --color-focused: var(--ion-color-primary-shade);
  --color-activated: var(--ion-color-primary-shade);
  font-size: inherit;
}

.segment-holder {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.segment-holder ion-segment {
  flex: 0 0 auto;
}

.segment-holder .segment-content {
  flex: 1 1 auto;
  overflow: hidden;
  overflow-y: auto;
}

.scrollable {
  overflow: auto;
}

.scrollable-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.scrollable-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.bordered,
.bordered-top,
.bordered-bottom,
.bordered-left,
.bordered-right {
  border: 1px solid var(--okx-border-color);
}

.bordered-primary {
  border: 1px solid var(--ion-color-primary);
}

.bordered-top {
  border-width: 1px 0 0;
}

.bordered-bottom {
  border-width: 0 0 1px;
}

.bordered-left {
  border-width: 0 0 0 1px;
}

.bordered-right {
  border-width: 0 1px 0 0;
}

.underlined {
  text-decoration: underline;
  text-decoration-color: var(--ion-color-tertiary);
}
.capitalized {
  text-transform: capitalize !important;
}
.uppercase {
  text-transform: uppercase;
}
ion-segment-button {
  height: 50px;
  border-radius: 0px;
  color: var(--ion-color-dark);
  border: 0 none;
  border-bottom: 2px solid var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary-contrast);
  font-weight: bold;
}

ion-segment-button.segment-button-checked {
  border-bottom-color: var(--ion-color-border);
  color: var(--ion-color-primary);
}

.clickable {
  cursor: pointer;
}

.field-error {
  color: var(--ion-color-danger);
  font-size: var(--okx-error-font-size);
  margin: var(--okx-error-margin);
  font-weight: 500;
}

ion-checkbox {
  --ion-color-secondary-contrast: #ffffff;
  --border-radius: 50%;
  height: 20px;
  width: 20px;
}

.absolute-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.flex-col-wrapper,
.flex-col-holder,
.flex-row-wrapper,
.flex-row-holder {
  display: flex;
}

.flex-col-wrapper,
.flex-col-holder {
  flex-direction: row;
}

.flex-col-wrapper.flex-reverse,
.flex-col-holder.flex-reverse {
  flex-direction: row-reverse;
}

.flex-row-wrapper,
.flex-row-holder {
  flex-direction: column;
}

.flex-row-wrapper.flex-reverse,
.flex-row-holder.flex-reverse {
  flex-direction: column-reverse;
}

.flex-col-wrapper > div,
.flex-col-holder > div,
.flex-row-wrapper > div,
.flex-row-holder > div {
  flex: 1 1 auto;
}

.flex-col-wrapper > div.flex-min,
.flex-col-holder > div.flex-min,
.flex-row-wrapper > div.flex-min,
.flex-row-holder > div.flex-min {
  flex: 0 1 var(--okx-flex-min);
}

.flex-col-wrapper > div.flex-optional,
.flex-col-holder > div.flex-optional,
.flex-row-wrapper > div.flex-optional,
.flex-row-holder > div.flex-optional {
  flex: 1 1 0;
}

.flex-col-wrapper > div.flex-spacer,
.flex-col-holder > div.flex-spacer,
.flex-row-wrapper > div.flex-spacer,
.flex-row-holder > div.flex-spacer {
  flex: 0 1 var(--okx-flex-spacer);
}

.flex-centered {
  align-items: center;
}

.flex-stretched {
  align-items: stretch;
}

.two-column-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.two-column-list.between > *:nth-of-type(even) {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  justify-self: flex-end;
  place-items: end;
}

.two-column-list.between > *:nth-of-type(odd) {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
  place-items: start;
}

.two-column-list.center > * {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  place-items: center;
}

.auto-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.absolute-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding-inline-start: var(--ion-padding, 16px);
  padding-inline-end: var(--ion-padding, 16px);
  padding-left: var(--ion-padding, 16px);
  padding-right: var(--ion-padding, 16px);
  padding-top: var(--ion-padding, 16px);
  padding-bottom: var(--ion-padding, 16px);
}

.centered,
.centered * {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.righted,
.righted * {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}

.righted,
.righted * .grow {
  flex-grow: 0;
}

.righted,
.righted * .instruction-grow {
  flex-grow: 1;
}

.lefted,
.lefted * {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: flex-start;
}

.self-center {
  align-self: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.bold {
  font-weight: bold;
}

.inline-block {
  display: inline-block;
}

.hidden,
.web-only {
  display: none;
}

.mobile-only {
  display: block;
}

.invisible {
  visibility: hidden;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

hr {
  color: transparent;
  background-color: transparent;
  height: 0;
  min-height: 0;
  line-height: 0;
  margin: var(--okx-hr-margin);
  border: 0 none;
  border-top: 2px solid var(--ion-color-gray);
}

ion-badge.small {
  width: 15px;
  height: 15px;
  line-height: 13px;
  font-size: 7px;
  --padding-start: 2px;
  --padding-end: 2px;
  --padding-top: 2px;
  --padding-bottom: 2px;
  color: var(--ion-color-white) !important;
  border-radius: 50%;
  min-width: 11px;
  text-transform: none;
  margin-left: 5px;
  position: absolute;
}

.card-thumb {
  display: inline-block;
  width: 55px;
  height: 35px;
  /*background-color: var(--ion-color-light);*/
  position: relative;
  border-radius: 3px;
  border: 1px solid var(--ion-color-light);
}

.card-thumb.visa::after {
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  bottom: 0;
  content: 'VISA';
  color: #2566af;
  text-align: center;
  font-weight: 900;
  font-style: italic;
}

.card-thumb.mc::before {
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 26px;
  height: 26px;
  content: '';
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background-color: #c00;
}

.card-thumb.mc::after {
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  right: 0;
  bottom: 0;
  content: 'MasterCard';
  color: #fff;
  font-size: 8px;
  padding-top: 11px;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
  /*background-image: radial-gradient(circle at 36px 16px, #f90 0px 12px,transparent 13px);*/
  background-image: radial-gradient(
    circle at 36px 16px,
    rgba(255, 153, 0, 0.7) 0px 12px,
    transparent 13px
  );
}

ion-input:-webkit-autofill,
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #999;
}

ion-input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: transprent, 0 0 0 50px white inset;
  -webkit-text-fill-color: #999;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* Specific style, text seems like input-label & input are inline */
.inline-input {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.inline-input--bordered {
  border-bottom: 1px solid var(--ion-color-light);
}

.blocked-input {
  width: 100%;
  display: block;
}

.blocked-input--bordered {
  border-bottom: 1px solid rgba(var(--ion-color-light-rgb), 1);
}

.inline-input > ion-text,
.inline-input > label {
  align-self: center;
  flex: 0 0 40%;
}

.inline-input.inline-input--validate {
  justify-content: initial;
  border-bottom: 0;
}

.inline-input.inline-input--validate .data-picker-input {
  width: 100%;
}

.inline-input > ion-text > span,
.blocked-input > ion-text > span {
  font-weight: bold;
  color: var(--ion-color-primary);
  font-size: var(--okx-default-font-size);
}

.inline-input > ion-input,
.blocked-input > ion-input {
  --placeholder-color: var(--ion-color-gray);
  --placeholder-opacity: 1;
  text-transform: initial;
  /* margin-inline-start: 20px; */
}

.blocked-input > ion-input {
  margin-inline-start: 0;
}

.inline-input.inline-input-gaped > ion-input {
  margin-inline-start: 50px;
}

.round-button {
  --border: 1px solid;
  --border-radius: 20px;
}

/* text fileds */
.title {
  position: relative;
  font-size: var(--okx-title-font-size);
  color: var(--okx-default-text-color);
  padding: 0;
  margin: 0;
  z-index: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sectiontitle {
  font-size: var(--okx-sectiontitle-font-size);
  font-weight: bold;
  color: var(--okx-default-text-color);
}
.subtitle,
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--okx-subtitle-font-size);
  color: var(--okx-default-text-color);
  font-family: primary-bold;
}

ion-label h2,
ion-label h3,
ion-label h4,
ion-label h5,
ion-label h6 {
  font-size: var(--okx-font-size) !important;
}
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p,
div,
li,
ion-item,
.strong-text,
.normal-text {
  font-size: var(--ion-font-size);
}
p,
div,
li,
.strong-text,
.normal-text {
  color: var(--okx-default-text-color);
}

.small-text {
  font-size: var(--okx-sectiontitle-font-size);
  font-family: secondary-regular !important;
}
.very-small-text {
  font-size: var(--okx-small-text-font-size);
}

.light-text {
  color: var(--okx-light-text-color);
}

/* components */

.web .ion-padding {
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  margin: auto !important;
  background-color: #fff !important;
  border-radius: var(--okx-box-radius) !important;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.25) !important;
  max-height: 90% !important;
  overflow: hidden !important;
  /* height: auto !important; */
}

.ion-padding ion-list > ion-item {
  --border-color: rgba(var(--ion-color-light-rgb), 1);
}

ion-radio {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  border: 1px solid var(--ion-color-gray);
}
ion-radio{
  margin-right: 10px;
}
ion-radio.radio-checked {
  background-color: var(--ion-color-secondary);
  border: 1px solid var(--ion-color-secondary);
}
.web ion-radio.radio-checked {
  background-color: var(--ion-color-white);
  border: 1px solid transparent;
}

.title,
.strong-text.roboto-slab,
.dash-menu ion-label,
.history-item ion-col:last-child p,
.item-details-card-header > * ,
ion-content.route-contact-details .inline-input > ion-text,
ion-content.route-contact-details .inline-input > label {
  font-family: primary-bold !important;
}
.normal-text.roboto-slab{
  font-family: primary-regular;
}
.normal-text.primary-regular{
  font-family: primary-regular;
}
.normal-text.roboto-slab-regular{
  font-family: primary-regular;
}
.block{
  display: block;
}
ion-button,
.strong-text,
.validate-modal > .modal-wrapper .ion-page .inline-input > ion-text .small-text,
.validate-modal > .modal-wrapper .ion-page .inline-input > label {
  font-family: primary-bold;
}
.normal-text {
  font-family: primary-regular;
}
.thin-text {
  font-family: primary-light;
}
.drawer-menu .nav-label {
  font-family: primary-regular;
}

.no-borders{
  --border-style: none !important
}
.big-text{
font-size: 13px;
}