@import url('./index.css');

.web * {
  scrollbar-width: thin !important;
  scrollbar-color: var(--okx-scrollbar-color);
}

.web ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.web ::-webkit-scrollbar-track {
  background: var(--okx-scrollbar-track-color);
  border-radius: 0;
}

.web ::-webkit-scrollbar-thumb {
  background: var(--okx-scrollbar-handle-color);
  border-radius: 0;
}
.web .web-only {
  display: block;
}

.web .mobile-only {
  display: none;
}
.web  .ion-page > ion-content {
  /* background-color: #fff; */
  background-image: url(../assets/images/backgrounds/main-bg.jpg);
  background-position: 50% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.web .static ion-toolbar.primary-toolbar {
  --background: transparent none;
}

.web .mbsc-select-input {
  cursor: pointer;
}

.web .mbsc-select-input[disabled],
.web .mbsc-sel-hdn[disabled] + .mbsc-select-input {
  cursor: default;
}

.web .static.route-register + .dynamic-header {
  display: none;
}

.web
  #main
  > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history):not(.route-click-and-collect):not(.route-social-media):not(.route-order-to-table)
  > .no-padding,
.web #main > ion-content > .ion-padding {
  
  width: var(--okx-bsmodal-width-lg);
 
}

.web .restaurant-card ion-card-content ion-button:last-of-type {
  margin: 0;
}


@media screen and (max-width: 1200px) {
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding {
    width: var(--okx-bsmodal-width-md);
    margin-left: calc(var(--okx-bsmodal-width-md) / -2);
  }
  .web .restaurant-card {
    width: var(--okx-bsmodal-width-md);
  }
}

@media screen and (max-width: 960px) {
  .web
    #main
    > ion-content:not(.route-order):not(.route-dashboard):not(.route-delivery-options):not(.route-delivery-address-check):not(.route-locations):not(.route-refer-a-friend):not(.route-pick-up-point-check):not(.route-history)
    > .no-padding,
  .web #main > ion-content > .ion-padding {
    width: var(--okx-bsmodal-width-sm);
    margin-left: calc(var(--okx-bsmodal-width-sm) / -2);
  }
  .web .restaurant-card {
    width: var(--okx-bsmodal-width-sm);
  }
}

@media screen and (max-width: 991px) {
  .web .collapse-drawer-icon {
    display: block;
    display: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    cursor: pointer;
    font-size: 25px;
  }
}

/* responsive end */

/* big screen override */

@media screen and (min-width: 992px) {
  .web ion-header:not(.route-login) + ion-toolbar.dynamic-header,
  .web ion-header:not(.route-login) + .content-spinner + ion-toolbar.dynamic-header {
    top: 16px;
    width: 100px;
  }
}
